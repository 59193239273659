import React, {useState} from "react"
import { FaEnvelope } from 'react-icons/fa'
import { navigate } from 'gatsby'
import {useDispatch, useSelector} from "react-redux"

import "./union-member-single.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import {onChangeByName} from "../redux/registration/registrationAction"
import {checkMember,checkWctoh,checkUniqMail} from "../redux/api/apiAction"
import {isDefined} from "../utils/data"
import HelpUr from "../components/help/help"

const UnionMemberSinglePage = () => {
    const dispatch  = useDispatch()
    const errorAPI  = useSelector(({apiReducer}) => apiReducer.login_failed)
    const [success, setSuccess] = useState(false)
    const [error, setError]     = useState(false)
    const [email, setEmail]     = useState("")
    const [validationEmail, setValidationEmail] = useState("")
    const [over, setOver]     = useState(false)

    const onChange = e => {
        const {value} = e.target
        setEmail(value)
            setValidationEmail("")
    }

    const onSubmit = e => {
        e.preventDefault()

        if(email === ""){
            setValidationEmail("Enter your email")
        }else{
            setOver(true)
            dispatch(checkUniqMail(email))
            .then(res => {
                if(res === false) {
                    setOver(false)
                    setValidationEmail("A user with this email is already registered")
                }else{
                    dispatch(checkMember(email))
                    .then(res => {
                        if(res === true) {
                            setEmail("")
                            setSuccess(true)
                            setError(false)
                            dispatch(onChangeByName("unionMember", true))
                            navigate("/individual")
                        }
                        else {
                            setEmail(email)
                            setSuccess(true)
                            setError(false)
                            dispatch(onChangeByName("registered_mail", email))
                            dispatch(onChangeByName("confirmEmail", email))
                            navigate("/individual")
                        }
                    })
                }
            })         
            
        }  



    }

    return (
                <Layout>            
                    <div id="overlay_urevent" className={over ? "d-block" : "d-none"}>
                        <div id="text_overlay">
                            <div class="lds-ripple">
                                <div></div>
                                <div></div>
                            </div> Please wait, the database is being reviewed.
                        </div>
                    </div>
            <SEO title="Registration" />
        <div className={"col-10 mx-auto"}>
                <form onSubmit={onSubmit}>
                    <div className="form-row">
                        <div className={"col-12"}>
                        <p className={"title text-center"}>Union Members receive a discounted fee, become a Member today and you’ll also receive 15 months membership for the price of 12.<br/><a href="https://membership.theunion.org/registration/landing">Join here</a><br/><br/>Already a member? Don’t forget to renew your membership before registration<br/><a href="https://membership.theunion.org/login">Renew today</a><br/><br/>Summit participants also receive a discounted fee for World Conference<br/><br/><b>To start your registration please use your same your membership, summit or speaker email</b></p>
                        </div>
                        <div className={"col-1"}>
                            <label><FaEnvelope/></label>
                        </div>
                        <div className={"col-11"}>
                            <input type="email" className="form-control" placeholder="Email" name={"email"} value={email} onChange={onChange}/>
                            {validationEmail !== "" ?
                                <div className="invalid-tooltip">{validationEmail}</div>
                            : ""}
                        </div>                        
                    </div>
        
                    
                <div className={"content-footer"}>
                    <a className={"sr-btn-next btn btn-secondary"} href={"https://union2021.urevent.fr/login"}>Already registered ? Login</a>
                    <small  className={"help"}><HelpUr/></small>
                    <button type={"submit"} className={"sr-btn-next btn btn-info"}>Next</button>
                </div>

                </form>
            </div>
        </Layout>
    )
}

export default UnionMemberSinglePage
